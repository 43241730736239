
.root {
  display: flex;
  flex-direction: row;
}

.leftroot {
  flex: auto;
  background-color: lightgray;
}

.middleroot {
  display: flex;
  flex: 7;
  flex-direction: column;
  background-color: #DDDDDD;
}

.rightroot {
  flex: auto;
  background-color: lightgray;
}
