.spheader {
    background-color: #EAEAEA;
    border-radius: 13px;
    height: 120px ;
    /* flex: auto; */
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 7px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 1px;
}

.spbody {
    display: flex;
    flex-direction: row;
    min-height: 1000px;
}

.spsidebar {
    border-radius: 13px;
    background-color: #EAEAEA;
    /* justify-content: center; */
    align-items: start;
    display: flex;
    flex-direction: column;
    margin: 7px;
    width: 12%;
    min-width: 150px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 1px;
}

.spsidebaritem {
    padding: 5px;
    display: flex;
    list-style: none;
}

nav a {
    text-decoration: none;
    list-style: none;
    padding: 0px;
    display: flex;   
    text-align: left; 
}

.spcontent {
    background-color: #EAEAEA;
    justify-content: center;
    align-items: center;
    border-radius: 13px;
    display: flex;
    width: 100%;
    margin: 7px;
}

.spfooter {
    background-color: #EAEAEA;
    padding: 25px 50px;
    height: 100px;
    border-radius: 13px;
    margin: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 1px;
}

